<template>
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <!--begin::Subheader-->
        <!--end::Subheader-->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <!--begin::Dashboard-->
                <!--begin::Row-->
                <div class="row text-center">
                  <div class="livecoinwatch-widget-5"  lcw-base="USD" lcw-color-tx="#333333" lcw-marquee-1="coins" lcw-marquee-2="movers" lcw-marquee-items="30" ></div>

                </div>
              <div class="row" style="margin-left: 0px;">
                  <div class="livecoinwatch-widget-1" style="width: 25%" lcw-coin="BTC" lcw-base="USD" lcw-secondary="BTC" lcw-period="d" lcw-color-tx="#000000" lcw-color-pr="#58c7c5" lcw-color-bg="#e6e6e6" lcw-border-w="5" ></div>
                  <div class="livecoinwatch-widget-1" style="width: 25%" lcw-coin="ETH" lcw-base="USD" lcw-secondary="BTC" lcw-period="d" lcw-color-tx="#000000" lcw-color-pr="#58c7c5" lcw-color-bg="#e6e6e6" lcw-border-w="5" ></div>
                  <div class="livecoinwatch-widget-1" style="width: 25%" lcw-coin="BNB" lcw-base="USD" lcw-secondary="BTC" lcw-period="d" lcw-color-tx="#000000" lcw-color-pr="#58c7c5" lcw-color-bg="#e6e6e6" lcw-border-w="5" ></div>
                  <div class="livecoinwatch-widget-1" style="width: 25%" lcw-coin="XRP" lcw-base="USD" lcw-secondary="BTC" lcw-period="d" lcw-color-tx="#000000" lcw-color-pr="#58c7c5" lcw-color-bg="#e6e6e6" lcw-border-w="5" ></div>

                </div>
                <div class="row mb-6">
                    <div class="col-lg-6">
                        <!--begin::Mixed Widget 14-->
                        <div class="card card-custom">
                        <div class="card-header flex-wrap border-0 pt-6 pb-0">
                            <div class="card-title">
                            <h3 class="card-label">
                                {{ $t('miracle_deal.keeper_list') }}
                            </h3>
                            </div>
                        </div>
                        <div class='card-body'>
                            <div class="table-responsive">
                            <table class="table datatable">
                                <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{ $t('commons.currency_code') }}</th>
                                <th scope="col">{{ $t('commons.quantity') }}</th>
                                <th scope="col">{{ $t('miracle_deal.fee') }}</th>
                                <th scope="col">{{ $t('commons.duration') }}</th>
                                <th scope="col">{{ $t('miracle_deal.customer_ratio') }}</th>
                                <th scope="col">{{ $t('miracle_deal.countdown') }}</th>
                                <th scope="col">{{ $t('commons.status') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in striker" :key="'striker-' + index">
                                <th class="font-weight-bolder">{{ item.id }}</th>
                                <td>
                                    <span style="width: 250px;">
                                    <div class="d-flex align-items-center">
                                        <div class="symbol symbol-40 flex-shrink-0">
                                        <div class="media align-items-center">
                                            <img :src="`${iconUrl}${item.currencyCode.toLowerCase()}.png`" height="30" alt="" class="cc mr-2 font-large-2 warning d-none d-md-block">
                                        </div>
                                        </div>
                                        <div class="ml-3">
                                        <div class="text-dark-75 font-weight-bold line-height-sm">{{ item.currencyCode }}</div>
                                        </div>
                                    </div>
                                    </span>
                                </td>
                                <td>{{ item.quantity }}</td>
                                <td class="text-muted">% {{ item.fee }}</td>
                                <td class="text-muted">{{ item.duration }} {{ textDuration(item.durationType) }}</td>
                                <td class="text-muted">% {{ item.ratio }}</td>
                                <td>
                                    <span style="width: 250px;">
                                    <div class="d-flex align-items-center">
                                        <div class="ml-2">
                                        <div v-if="item.signDate" class="text-primary font-weight-bold line-height-sm">{{ item.signDate.date  | dateFormat }}</div>
                                        <div class="font-size-sm text-dark-50">{{ item.time_ago }}</div>
                                        </div>
                                    </div>
                                    </span>
                                </td>
                                <td>
                                    <span :class="['label label-lg label-inline', item.status === 0 ? 'label-light-danger' : item.status === 1 ? 'label-light-warning' : 'label-light-primary'] ">
                                    {{ item.status === 0 ? $t('miracle_deal.close') : item.status === 1 ? $t('miracle_deal.waiting') : $t('miracle_deal.in_progress', {"space": "&nbsp;"}) }}
                                    </span>
                                </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>
                        <!--end::Mixed Widget 14-->
                    </div>
                    <div class="col-lg-6">
                        <!--begin::Advance Table Widget 4-->
                        <div class="card card-custom">
                          <div class="card-header flex-wrap border-0 pt-6 pb-0">
                            <div class="card-title">
                              <h3 class="card-label">
                                {{ $t('miracle_deal.striker_list') }}
                              </h3>
                            </div>
                          </div>
                          <div class='card-body'>
                            <div class="table-responsive">
                              <table class="table datatable">
                                <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">{{ $t('commons.currency_code') }}</th>
                                  <th scope="col">{{ $t('commons.quantity') }}</th>
                                  <th scope="col">{{ $t('miracle_deal.fee') }}</th>
                                  <th scope="col">{{ $t('commons.duration') }}</th>
                                  <th scope="col">{{ $t('miracle_deal.customer_ratio') }}</th>
                                  <th scope="col">{{ $t('miracle_deal.countdown') }}</th>
                                  <th scope="col">{{ $t('commons.status') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in keeper" :key="'keeper-' + index">
                                  <th class="font-weight-bolder">{{ item.id }}</th>
                                  <td>
                                    <span style="width: 250px;">
                                      <div class="d-flex align-items-center">
                                        <div class="symbol symbol-40 flex-shrink-0">
                                          <div class="media align-items-center">
                                            <img :src="`${iconUrl}${item.currencyCode.toLowerCase()}.png`" height="30" alt="" class="cc mr-2 font-large-2 warning d-none d-md-block">
                                          </div>
                                        </div>
                                        <div class="ml-3">
                                          <div class="text-dark-75 font-weight-bold line-height-sm">{{ item.currencyCode }}</div>
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                  <td>{{ item.quantity }}</td>
                                  <td class="text-muted">% {{ item.fee }}</td>
                                  <td class="text-muted">{{ item.duration }} {{ textDuration(item.durationType) }}</td>
                                  <td class="text-muted">% {{ item.ratio }}</td>
                                  <td>
                                    <span style="width: 250px;">
                                      <div class="d-flex align-items-center">
                                        <div class="ml-2">
                                          <div v-if="item.processDate" class="text-primary font-weight-bold line-height-sm">{{ item.processDate.date  | dateFormat }}</div>
                                          <div class="font-size-sm text-dark-50">{{ item.time_ago }}</div>
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                    <td>
                                    <span :class="['label label-lg label-inline', item.status === 0 ? 'label-light-danger' : item.status === 1 ? 'label-light-warning' : 'label-light-primary'] ">
                                      {{ item.status === 0 ? $t('miracle_deal.close') : item.status === 1 ? $t('miracle_deal.waiting') : $t('miracle_deal.in_progress', {"space": "&nbsp;"}) }}
                                    </span>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <!--end::Advance Table Widget 4-->
                    </div>
                </div>
                <!--end::Row-->
                <!--end::Dashboard-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
</template>


<script>
import dateFormat from '@/config/_date-format';
import {mapState, mapActions} from 'vuex';

export default {
  name: 'Tv',
  data() {
    return {
      iconUrl: "https://cdn.test.ofinans.com/images/currency/",
      transactionsIntervalId: null,
    }
  },
  filters: {
    dateFormat
  },
  computed: {
    ...mapState({
      striker: state => state.striker.strikerList,
      keeper: state => state.keeper.keeperList
    }),
  },
  methods: {
    ...mapActions('striker', ['GET_STRIKER_TV', 'DELETE_STRIKER']),
    ...mapActions('keeper', ['GET_KEEPER_TV', 'DELETE_KEEPER']),
    textDuration(id) {
      switch (id) {
        case 0:
          return 'min';
        case 1:
          return 'h';
        case 2:
          return 'd';
        case 3:
          return 'w';
        case 4:
          return 'm';
        default:
          return 'y';
      }
    },
    refreshLists() {
      clearInterval(this.transactionsIntervalId);
      this.transactionsIntervalId = setInterval(() => {
        this.GET_STRIKER_TV();
        this.GET_KEEPER_TV();
      }, 30*1000);
    }
  },
  created() {
    const body = document.body;
    body.classList.add("aside-minimize");

    var livecoinwatch_script = document.createElement('script');
    livecoinwatch_script.setAttribute('src','https://www.livecoinwatch.com/static/lcw-widget.js');
    document.head.appendChild(livecoinwatch_script);

    this.GET_STRIKER_TV();
    this.GET_KEEPER_TV();
    this.refreshLists();
  },
  destroyed() {
    clearInterval(this.transactionsIntervalId);
  },
}
</script>
<style lang="scss" scoped>

  .header {
    display: none!important;
  }
  .header-fixed.subheader-fixed.subheader-enabled .wrapper{
    padding-top: 0;
  }
  .content {
    padding: 0;
  }
  .livecoinwatch-widget-5 {
    margin: 0 auto!important;
    display: inline-block!important;
  }
  .livecoinwatch-widget-1 {
    margin-bottom: 30px!important;
    margin-left: 30px!important;
    margin-right: 25px!important;
  }
</style>
